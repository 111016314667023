import {Box, Button, Chip, Grid, Paper, Typography} from "@mui/material";
import {CalendarMonth} from "@mui/icons-material";
import React, {useState} from "react";
import {useNotify} from "react-admin";
import {useNavigate} from "react-router-dom";

export const RdvAccordion = ({rdv, dossier,currentUser}) => {

  const [openNotes, setOpenNotes] = useState(false)
  const notify = useNotify()
  const navigate = useNavigate()

  const handleOpenNotes = () => {
    if(!(currentUser.antennes.includes(parseInt(dossier.antenne)) || (rdv.praticien && parseInt(rdv.praticien.id) === currentUser.id))){
      notify(`Vous n'êtes pas autorisé à voir les notes de ce rdv. Vous ne faites partie ni de l'antenne du dossier et vous n'êtes pas le praticien du dossier.`,{type:'warning'})
    }else{
      setOpenNotes(!openNotes)
    }
  }

  return <Grid key={rdv.id} item xs={12}>
    <Paper sx={{p:2, cursor:'pointer'}}>
      <Box sx={{display:'flex'}}>
        <CalendarMonth sx={{mr:1}}/>
        <Typography>{(new Date(rdv.date_rdv)).toLocaleDateString()}</Typography>
      </Box>
      {rdv.type && <Typography sx={{mt:1}}>Type : {(rdv.type).charAt(0).toUpperCase() + rdv.type.slice(1)}</Typography>}
      {rdv.praticien && <Typography sx={{mt:1}}>Professionnel : {rdv.praticien.prenom} {rdv.praticien.nom}</Typography>}
      {rdv.est_honore ? <Chip label="Honoré" color="success" sx={{mt:1}}/> : <Chip label="Pas honoré" color="error" sx={{mt:1}}/>}
      {(currentUser.antennes.includes(parseInt(dossier.antenne)) || (rdv.praticien && parseInt(rdv.praticien.id) === currentUser.id)) && (
        <>
          <br/>
          <Button variant="contained" size="small" sx={{mt:2}} onClick={handleOpenNotes}>{openNotes ? 'Fermer' : 'Ouvrir'} les notes</Button>
          {openNotes && (
            <Paper elevation={1} sx={{my: 2, p:2, border: '1px black solid'}}>
              <Typography dangerouslySetInnerHTML={{__html:rdv.notes}}></Typography>
            </Paper>
          )}
        </>
      )}
      {(parseInt(dossier.referent) === currentUser.id || (rdv.praticien && parseInt(rdv.praticien.id) === currentUser.id)) && <Button variant="outlined" onClick={() => navigate(`/rdvs/${rdv.id}/edit?dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier</Button>}
    </Paper>
  </Grid>
}
