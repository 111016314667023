import {Box, Button, Chip, Typography, Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const BeneficiaireRecap = ({viewType = "edit", beneficiaire, setBeneficiaire}) => {
    const navigate = useNavigate();

    return <Paper sx={{width:'100%',p:3}}>
        <Typography variant="h6" sx={{mb:2}}><u>Bénéficiaire</u></Typography>
        <Typography>{beneficiaire.prenom} {beneficiaire.nom} ({beneficiaire.type})</Typography>
        {viewType === "edit" && <Box sx={{display:'flex',py:1, alignItems: 'center'}}>
            <Typography sx={{mr:1}}>Dossiers : </Typography>
            {beneficiaire.dossiers.map((dossier) => <Chip key={dossier.id} onClick={() => navigate(`/dossiers/${dossier.id}/show`)} label={dossier.numero_dossier} />)}
        </Box>}
        {beneficiaire.type === "adulte" && <>
                <Typography>{beneficiaire.activite} - {beneficiaire.profession}</Typography>
                {beneficiaire.universite && <Typography>{beneficiaire.universite}</Typography> }
            </> || beneficiaire.type === "enfant" &&
          <Typography>{beneficiaire.classe} - {beneficiaire.ecole}</Typography>
        }
        {beneficiaire.sms_accepte && <Typography>Tél : {beneficiaire.telephone}</Typography>}
        {beneficiaire.mail_accepte && <Typography>Email : {beneficiaire.adresse_mail}</Typography>}
        <Typography>Née le {(new Date(beneficiaire.date_de_naissance)).toLocaleDateString()} à {beneficiaire.lieu_de_naissance}</Typography>
        <Typography>{beneficiaire.adresse}, {beneficiaire.ville} {beneficiaire.code_postal}</Typography>
        {viewType === "edit" && <>
            <Button sx={{mt:2}} variant="contained" onClick={() => setBeneficiaire(null)}>Changer de bénéficiaire</Button>
            <Button sx={{mt:2, ml:2}} variant="outlined" onClick={() => navigate(`/beneficiaires/${beneficiaire.id}/edit?redirectTo=dossiers`)}>Modifier le bénéficiaire</Button>
        </>}
    </Paper>
}
