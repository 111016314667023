import {
    Create,
    TextInput,
    SimpleForm,
    useNotify,
    useDataProvider,
    DateInput,
    ReferenceArrayInput,
    BooleanInput, FormDataConsumer, required
} from "react-admin";
import {useSearchParams, useNavigate} from "react-router-dom";
import React from "react";
export const AuteurCreate = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        formData.evenement = searchParams.get('evenement_id')
        const dossierId = searchParams.get('dossier_id')
        await dataProvider.create('auteurs',{data:formData})
        notify('Auteur créé',{type: 'success'})
        navigate(`/dossiers/${dossierId}/show`)
    }

    return <Create>
        <SimpleForm onSubmit={handleSubmit}>
            <TextInput source="nom_lien_victime" validate={required()}/>
            <DateInput source="date_naissance" validate={required()}/>
            <BooleanInput source="victime_enfance"/>
            <TextInput source="profession"/>
            <ReferenceArrayInput source="addictions" reference="addictions"/>
            <BooleanInput source="antecedant_judiciaire"/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.antecedant_judiciaire &&
                    <TextInput source="antecedant_details"/>
                }
            </FormDataConsumer>

            <BooleanInput source="auteur_incarcere"/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.auteur_incarcere &&
                    <>
                        <DateInput source="incarceration_date"/>
                        <TextInput source="incarceration_temps"/>
                    </>
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
}