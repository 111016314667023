import {Box, Paper, Typography, TextField, Button} from "@mui/material";
import axios from "axios";
import {SyntheticEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export const ResetPassword = () => {
    const navigate = useNavigate()
    const search = useLocation().search
    const [code, setCode] = useState(null)
    const [codeSend, setCodeSend] = useState(false)

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault()
        axios.post(`${import.meta.env.VITE_STRAPI_API_URL}/auth/forgot-password`,{
            email: e.target.elements.email.value
        }).then(response => {
            setCodeSend(true)
        })
        .catch(error => {
            console.log('An error occurred:', error.response);
        });
    }
    const handleSubmitForgot = async (e: SyntheticEvent) => {
        e.preventDefault()
        try{
            const response = await axios.post(`${import.meta.env.VITE_STRAPI_API_URL}/auth/reset-password`,{
                code: code,
                password: e.target.elements.password.value,
                passwordConfirmation: e.target.elements.confirmPassword.value
            })

            const jwt = response.data.jwt

            const requestUser = new Request(`${import.meta.env.VITE_STRAPI_API_URL}/users/me?populate=*`,{
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${jwt}`
                }),
            })
            
            try {
                const user = await (await fetch(requestUser)).json()
                if(user.antennes){
                    localStorage.setItem('user',JSON.stringify({...user, antennes: user.antennes.map((antenne) => antenne.id)}))
                }else{
                    localStorage.setItem('user',JSON.stringify({...user}))
                }

                localStorage.setItem('token',jwt)
                navigate('/#/login')
            }catch (e){
                console.log(e)
            }
        }catch (e){
            console.log(e)
        }
    }

    useEffect(() => {
        if(new URLSearchParams(search).has('code')){
            setCode(new URLSearchParams(search).get('code'))
        }
    },[])

    return <Box sx={{display:'flex',height:'100vh',backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);'}}>
        <Paper sx={{m:'auto',mt:6, width: '300px', p:2}}>
            <Typography variant="h6" sx={{textAlign:'center'}}>Mot de passe oublié</Typography>
            {codeSend &&
                <Typography sx={{mt:4, textAlign:'center'}}>Un email vous à été envoyé sur votre boite mail avec un lien de reinitialisation</Typography>
            || <>
                    {!code &&
                        <form onSubmit={handleSubmit}>
                            <TextField type="email" name="email" sx={{mt:4}} fullWidth variant="filled" label="Email"/>
                            <Button type="submit" sx={{mt:4}} variant="contained" fullWidth>Valider</Button>
                        </form>
                        || <form onSubmit={handleSubmitForgot}>
                            <TextField type="password" name="password" sx={{mt:4}} fullWidth variant="filled" label="Nouveau mot de passe"/>
                            <TextField type="password" name="confirmPassword" sx={{mt:2}} fullWidth variant="filled" label="Confirmation mot  de passe"/>
                            <Button type="submit" sx={{mt:4}} variant="contained" fullWidth>Valider</Button>
                        </form>
                    }
            </>}
        </Paper>
    </Box>
}