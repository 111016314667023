import {
  DatagridConfigurable,
  List,
  TextField,
  ReferenceField,
  TopToolbar,
  SelectColumnsButton,
  CreateButton,
  FilterList, FilterListItem,
  SearchInput,
  DateField, useTheme
} from 'react-admin';

import {
    Card,
    CardContent
} from '@mui/material'

import {ColoredChipField} from '../../components/coloredChipField.tsx'
import {AccessTime} from '@mui/icons-material'
import {useEffect, useState} from "react";

export const DossierList = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const theme = useTheme();
    const PostFilterSidebar = () => (
        <Card sx={{order: -1, mr: 2, mt: 8, width: 200}}>
            <CardContent>
                <FilterList label="Statut" icon={<AccessTime/>}>
                    <FilterListItem label="En cours" value={{ statut: "en_cours" }} />
                    <FilterListItem label="En sommeil" value={{ statut: "en_sommeil" }} />
                    <FilterListItem label="Clotûré" value={{ statut: "cloture" }} />
                </FilterList>
            </CardContent>
        </Card>
    );
    const PostListActions = () => (
        <TopToolbar>
            <SelectColumnsButton/>
            <CreateButton/>
        </TopToolbar>
    );

    const handleChangeInput = (e) => {
      console.log('ok')
    }

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.user))
    },[])

    const dossierFilters = [
        <SearchInput placeholder="Numéro dossier" source="q"  alwaysOn />,
        <SearchInput placeholder="Nom" source="custom_beneficiaire_nom_prenom" alwaysOn onChange={handleChangeInput}/>
    ]

    if(currentUser?.antennes){
        return <List aside={<PostFilterSidebar/>} actions={<PostListActions/>} filter={{antenne: currentUser?.antennes}} filters={dossierFilters}  sort={{ field: 'createdAt', order: 'DESC' }}>
            <DatagridConfigurable rowClick="show" bulkActionButtons={false}>
                <TextField source="numero_dossier" sx={{color:'#C24A57'}}/>
                <ReferenceField source="beneficiaire" reference="beneficiaires" link={false}/>
                <ReferenceField source="antenne" reference="antennes"/>
                <ColoredChipField source="statut" colorMapping={{"en_cours":{color:"success",label:"En cours"},"cloture": {color:"primary", label: "Clôturé"},"en_sommeil":{color:"warning",label: "En sommeil"}}}/>
                <DateField source="createdAt"/>
            </DatagridConfigurable>
        </List>
    }else{
        return <List aside={<PostFilterSidebar/>} actions={<PostListActions/>} filters={dossierFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
            <DatagridConfigurable rowClick="show" bulkActionButtons={false}>
                <TextField source="numero_dossier" sx={{color:'#C24A57'}}/>
                <ReferenceField source="beneficiaire" reference="beneficiaires" link={false}/>
                <ReferenceField source="antenne" reference="antennes"/>
                <ColoredChipField source="statut" colorMapping={{"en_cours":{color:"success",label:"En cours"},"cloture": {color:"primary", label: "Clôturé"},"en_sommeil":{color:"warning",label: "En sommeil"}}}/>
                <DateField source="createdAt"/>
            </DatagridConfigurable>
        </List>
    }
};
