import {
    Edit,
    SimpleForm,
    useNotify,
    useDataProvider, SelectInput
} from "react-admin";
import {useSearchParams, useNavigate} from "react-router-dom";
import React from "react";
export const DemarcheEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        const dossierId = searchParams.get('dossier_id')
        await dataProvider.update('demarches',{id:formData.id, data:formData})
        notify('Démarche modifiée',{type: 'success'})
        navigate(`/dossiers/${dossierId}/show`)
    }

    return <Edit>
        <SimpleForm onSubmit={handleSubmit}>
            <SelectInput label="Genre" source="statut" choices={[
                { id: 'passees', name: 'Passéees' },
                { id: 'en_cours', name: 'En cours' },
                { id: 'aucune', name: 'Aucune'}
            ]}/>
        </SimpleForm>
    </Edit>
}