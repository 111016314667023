import {Edit, FormDataConsumer, SelectInput, SimpleForm, TextInput, useDataProvider, useNotify} from "react-admin";
import {useNavigate, useSearchParams} from "react-router-dom";
import React from "react";

export const MaisonEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        const dossierId = searchParams.get('dossier_id')
        await dataProvider.update('maisons',{id:formData.id, data:formData})
        notify('Maison modifiée',{type: 'success'})
        navigate(`/dossiers/${dossierId}/show`)
    }

    return <Edit sx={{display:'flex'}}>
        <SimpleForm onSubmit={handleSubmit}>
            <SelectInput label="Informé consultation" source="maison_type" choices={[
                { id: 'foyer', name: 'Foyer' },
                { id: 'famille accueil', name: 'Famille d\'accueil' },
            ]}/>
            <TextInput source="nom"/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.maison_type === "foyer" &&
                    <>
                        <TextInput source="referent"/>
                        <TextInput source="referent_telephone"/>
                    </>
                || formData.maison_type === "famille accueil" &&
                    <TextInput source="telephone_maison"/>
                }
            </FormDataConsumer>
            <TextInput source="email"/>
            <TextInput source="adresse"/>
        </SimpleForm>
    </Edit>
}