import {
    BooleanField,
    DateField,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceArrayField,
    ChipField, SingleFieldList, useGetRecordId, useDataProvider
} from 'react-admin';
import {Chip, Divider, Typography, Box, Grid} from "@mui/material";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const BeneficiaireShow = () => {

    const beneficiaireId = useGetRecordId();
    const [beneficiaire, setBeneficiaire] = useState(null)
    const navigate = useNavigate();
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getOne("beneficiaires",{id:beneficiaireId}).then(({data: beneficiaireResp}) => {
            dataProvider.getMany("dossiers",{ids: beneficiaireResp.dossiers}).then(({data: dossiers}) => {
                setBeneficiaire({...beneficiaireResp, dossiers: dossiers})
            })
        })
    },[beneficiaireId])

    if(beneficiaire){
        return <Show title={`Beneficiaire ${beneficiaire.prenom} ${beneficiaire.nom}`}>
            <Box sx={{p:2, display:'flex', alignItems: 'center'}}>
                <Typography sx={{mr:1}}>Dossiers liés :</Typography>
                {beneficiaire && beneficiaire.dossiers.map((dossier) => <Chip key={dossier.id} onClick={() => navigate(`/dossiers/${dossier.id}/show`)} label={dossier.numero_dossier} />)}
            </Box>
            <Divider sx={{mb:2}}/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SimpleShowLayout divider={<Divider flexItem/>}>
                        <TextField source="genre"/>
                        <TextField source="nom"/>
                        <TextField source="prenom"/>
                        <TextField source="nationnalite"/>
                        <DateField source="date_de_naissance"/>
                        <TextField source="lieu_de_naissance"/>
                        <NumberField source="age"/>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={4}>
                    <SimpleShowLayout divider={<Divider flexItem/>}>
                        <TextField source="adresse"/>
                        <TextField source="ville"/>
                        <TextField source="code_postal"/>
                        <TextField source="telephone"/>
                        <BooleanField source="sms_accepte"/>
                        <BooleanField source="mail_accepte"/>
                        <TextField source="adresse_mail"/>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={4}>
                    <SimpleShowLayout divider={<Divider flexItem/>}>
                        <TextField source="couverture_sociale"/>
                        <TextField source="profession"/>
                        <TextField source="ecole"/>
                        <TextField source="classe"/>
                        <TextField source="type"/>
                        <TextField source="activite"/>
                        <BooleanField source="est_universite"/>
                        <TextField source="universite"/>
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    }
};