import {
    Create,
    SimpleForm,
    useNotify,
    useDataProvider, SelectInput
} from "react-admin";
import {useSearchParams, useNavigate} from "react-router-dom";
import React from "react";
export const DemarcheCreate = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        formData.evenement = searchParams.get('evenement_id')
        const dossierId = searchParams.get('dossier_id')
        await dataProvider.create('demarches',{data:formData})
        notify('Démarche créé',{type: 'success'})
        navigate(`/dossiers/${dossierId}/show`)
    }

    return <Create>
        <SimpleForm onSubmit={handleSubmit}>
            <SelectInput label="Genre" source="statut" choices={[
                { id: 'passees', name: 'Passéees' },
                { id: 'en_cours', name: 'En cours' },
                { id: 'aucune', name: 'Aucune'}
            ]}/>
        </SimpleForm>
    </Create>
}