import {Edit, TextInput, SimpleForm, ReferenceInput, useNotify, useDataProvider, SelectInput} from "react-admin";
import {useSearchParams, useNavigate} from "react-router-dom";
import React from "react";

export const IntervenantExterieurEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        await dataProvider.update('intervenant-exterieurs',{id:formData.id ,data:formData})
        notify('Intervenant extérieur modifié',{type: 'success'})
        navigate(`/dossiers/${formData.dossier}/show`)
    }

    return <Edit sx={{display:'flex'}}>
        <SimpleForm onSubmit={handleSubmit}>
            <TextInput source="nom"/>
            <TextInput source="coordonnees"/>
            <SelectInput source="type" choices={[
                { id: 'professionnel_juridique', name: 'Professionnel juridique' },
                { id: 'travailleur_social', name: 'Travailleur social' },
                { id: 'professionnel_medical_et_paramedical', name: 'Professionnel médical et para-médical'},
                { id: 'fiche de liaison', name: 'Fiche de liaison'},
                { id: 'association', name: 'Association'},
                { id: 'aide_sociale_enfance', name: 'Aide à l\'enfance'},
                { id: 'universite', name: 'Université'},
                { id: 'commissariat', name: 'Commissariat'},
                { id: 'hopital', name: 'Hôpital'},
                { id: 'media', name: 'Média'},
                { id: 'bouche_a_oreille', name: 'Bouche à oreille'},
                { id: 'professionnel_de_ws', name: 'Professionnel de WS'},
                { id: 'collectif', name: 'Collectif'},
                { id: 'etablissement_scolaire', name: 'Etablissement scolaire'},
                { id: 'entreprise', name: 'Entreprise'},
                { id: 'autre', name: 'Autre'},
            ]}/>
        </SimpleForm>
    </Edit>
}