import {Link, Login, LoginForm} from "react-admin";
import {Box} from "@mui/material";

export const LoginPage = (props) => (
    <Login {...props} >
        <LoginForm />
        <Box sx={{p:2, textAlign: 'center'}}>
            <Link to="/forget-password">Mot de passe oublié ?</Link>
        </Box>
    </Login>
);