import {
    Edit,
    TextInput,
    SimpleForm,
    useNotify,
    useDataProvider,
    BooleanInput, FormDataConsumer, NumberInput, SelectInput, DateInput
} from "react-admin";
import {useSearchParams, useNavigate} from "react-router-dom";
import React from "react";
export const PlainteEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        formData.demarche = searchParams.get('demarche_id')
        const dossierId = searchParams.get('dossier_id')
        await dataProvider.update('plaintes',{id:formData.id, data:formData})
        notify('Plainte modifié',{type: 'success'})
        navigate(`/dossiers/${dossierId}/show`)
    }

    return <Edit>
        <SimpleForm onSubmit={handleSubmit}>
            <BooleanInput source="main_courante"/>
            <TextInput source="lieu"/>
            <DateInput source="date"/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.main_courante && <NumberInput source="main_courante_nb"/> || <>
                    <BooleanInput source="plainte_retire"/>
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.plainte_retire && <>
                            <SelectInput label="Raison" source="raison" choices={[
                                { id: 'menace_auteur', name: 'Menace de l\'auteur' },
                                { id: 'peur', name: 'Peur' },
                                { id: 'ne_veut_pas_causer_de_tort_a_l_auteur', name: 'Ne veut pas causer de tort à l\'auteur'}
                            ]}/>
                        </>}
                    </FormDataConsumer>
                    <BooleanInput source="expertise_medicolegale"/>
                    <TextInput multiline source="commentaire"/>
                </>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.main_courante && <>

                </>}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
}