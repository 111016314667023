
const publicRoutes = [
    "/#/forget-password"
]
export const AuthProvider = {
    login: async ({ username, password }) => {
        const request = new Request(`${import.meta.env.VITE_STRAPI_API_URL}/auth/local`,{
            method: 'POST',
            body: JSON.stringify({identifier: username, password: password}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })

        let jwt;

        try {
            const responsePromise = await fetch(request)
            if(responsePromise.status !== 200){
                return Promise.reject()
            }
            const response = await responsePromise.json()
            jwt = response.jwt
        }catch (e){
            return Promise.reject()
        }

        const requestUser = new Request(`${import.meta.env.VITE_STRAPI_API_URL}/users?populate=*&filters[username][$eq]=${username}`,{
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`
            }),
        })

        let user;

        try {
            const response = await fetch(requestUser)
            if(response.status !== 200){
                return Promise.reject()
            }
            user = (await response.json())[0]
        }catch (e){
            console.log(e)
        }

        if(user.antennes){
            localStorage.setItem('user',JSON.stringify({...user, antennes: user.antennes.map((antenne) => antenne.id)}))
        }else{
            localStorage.setItem('user',JSON.stringify({...user}))
        }
        localStorage.setItem('token',jwt)

        return Promise.resolve({ redirectTo: '/dossiers' })
    },
    logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: () =>{
        if (publicRoutes.includes(window.location.pathname)) {
            return Promise.resolve()
        }

        return localStorage.getItem('user') ? Promise.resolve() : Promise.reject()
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        return Promise.resolve({
            id: JSON.parse(localStorage.getItem('user')).id,
            fullName: JSON.parse(localStorage.getItem('user')).username,
        })
    },
    getPermissions: () => Promise.resolve(),
};