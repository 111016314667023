import {
    BooleanInput,
    Edit,
    DateInput,
    FormDataConsumer,
    SelectInput,
    SimpleForm,
    TextInput, useDataProvider, useNotify,
    required, Toolbar, SaveButton
} from 'react-admin';

import {
    Box
} from '@mui/material';

import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

export const BeneficiaireEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();
    const handleSubmit = async (formData) => {
        const redirect = searchParams.get('redirectTo')
        const beneficiaire = await dataProvider.update('beneficiaires', {id:formData.id, data: formData})
        notify('Beneficiaire modifié', {type: 'success'})
        if(redirect === "dossiers"){
            return navigate(`/dossiers/create?beneficiaireId=${beneficiaire.data.id}`)
        }
        return navigate('/beneficiaires')
    }

    const BeneficiaireEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );

    return <Edit>
        <SimpleForm onSubmit={handleSubmit} toolbar={<BeneficiaireEditToolbar/>}>
            <Box sx={{display:'flex'}}>
                <SelectInput label="Type de dossier" sx={{mr:2}} source="type" validate={required()} choices={[
                    {id: 'adulte', name: 'Adulte'},
                    {id: 'enfant', name: 'Enfant'},
                ]}/>
                <SelectInput label="Genre" source="genre" sx={{mr:2}} validate={required()} choices={[
                    {id: 'homme', name: 'Homme'},
                    {id: 'femme', name: 'Femme'},
                    {id: 'autre', name: 'Autre'}
                ]}/>
                <TextInput sx={{mr:2}} validate={required()} source="nom"/>
                <TextInput validate={required()} source="prenom"/>
            </Box>

            <FormDataConsumer>
                {({formData, ...rest}) => formData.type == "adulte" &&
                    <>
                        <Box sx={{display:'flex'}}>
                            <SelectInput sx={{mr: 2}} source="activite" validate={required()} choices={[
                                {id: 'employe', name: 'Employé'},
                                {id: 'cadre', name: 'Cadre'},
                                {id: 'independant', name: 'Indépendant'},
                                {id: 'etudiant', name: 'Etudiant'},
                                {id: 'retraite', name: 'Retraité'},
                                {id: 'invalidite', name: 'Invalidité'},
                                {id: 'sans_emploi', name: 'Sans emploi'},
                                {id: 'arret_maladie', name: 'Arrêt maladie'},
                            ]}/>
                            <TextInput source="profession" validate={required()}/>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <BooleanInput source="est_universite"/>
                            <FormDataConsumer>
                                {({formData, ...rest}) => formData.est_universite &&
                                    <TextInput source="universite"/>
                                }
                            </FormDataConsumer>
                        </Box>
                    </>
                    || formData.type == "enfant" &&
                    <>
                        <TextInput sx={{pr: 3}} source="ecole" validate={required()}/>
                        <TextInput validate={required()} source="classe"/>
                    </>
                }
            </FormDataConsumer>
            <TextInput source="nationnalite" label="Nationnalité"/>
            <DateInput sx={{pr: 3}} validate={required()} source="date_de_naissance" label="Date de naissance"/>
            <TextInput source="lieu_de_naissance" validate={required()}/>
            <TextInput sx={{pr: 3}} source="adresse" validate={required()}/>
            <TextInput sx={{pr: 3}} source="code_postal" validate={required()}/>
            <TextInput sx={{pr: 3}} source="ville"/>
            <TextInput source="telephone"/>
            <BooleanInput source="sms_accepte"/>
            <BooleanInput source="mail_accepte"/>
            <TextInput source="adresse_mail" type="email"/>
            <SelectInput source="couverture_sociale" choices={[
                {id: 'securite_sociale', name: 'Sécurité sociale'},
                {id: 'CMU', name: 'CMU'},
                {id: 'AME', name: 'AME'},
                {id: 'invalidite', name: 'Invalidité'},
                {id: 'aucune', name: 'Aucune'}
            ]}/>
        </SimpleForm>
    </Edit>
}
