import {ChipField} from "react-admin";
import { useRecordContext } from "react-admin";
import {Chip} from "@mui/material";

export const ColoredChipField = ({source, colorMapping}) => {
    const record = useRecordContext();

    return (
        <Chip label={colorMapping[record.statut].label} color={colorMapping[record.statut].color}/>
    );
};