import {
    CreateButton,
    DatagridConfigurable,
    EmailField,
    FilterLiveSearch,
    List, SelectColumnsButton,
    TextField,
    TopToolbar
} from 'react-admin';
import {Card, CardContent} from "@mui/material";

export const BeneficiaireList = () => {

    const BeneficiaireFilterSidebar = () => (
        <Card sx={{order: -1, mr: 2, mt: 8, width: 200}}>
            <CardContent>
                <FilterLiveSearch/>
            </CardContent>
        </Card>
    );
    const BeneficiaireListActions = () => (
        <TopToolbar>
            <SelectColumnsButton/>
            <CreateButton/>
        </TopToolbar>
    );

    return <List aside={<BeneficiaireFilterSidebar/>} actions={<BeneficiaireListActions/>}>
        <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            <TextField source="nom"/>
            <TextField source="prenom"/>
            <EmailField source="adresse_mail"/>
            <TextField source="telephone"/>
        </DatagridConfigurable>
    </List>
};