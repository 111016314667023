
import { AppBar, TitlePortal} from 'react-admin';
import { Box, useMediaQuery, Theme } from '@mui/material';

export const CustomAppBar = () => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
        <AppBar color="primary" elevation={1}>
            <TitlePortal />
            <img style={{width:'20px',height:'20px',marginRight:'10px'}} src='https://static.wixstatic.com/shapes/ce2916_e8c07d5b62f147c7a04c17e708e20452.svg'/>
            <p>Womensafe</p>
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    );
}