import polyglotI18nProvider from 'ra-i18n-polyglot';

import fr from 'ra-language-french';
const translations = { fr };

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'fr', // default locale
    [{ locale: 'fr', name: 'Français' }],
);
