import {Button, Grid} from "@mui/material";
import {
    BooleanInput,
    DateInput,
    Form,
    FormDataConsumer,
    SelectInput,
    SimpleForm,
    TextInput,
    Edit,
    useDataProvider, useNotify, ReferenceArrayInput, useGetList
} from "react-admin";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
export const EnfantEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();
    const handleSubmit = async (formData) => {
        await dataProvider.update('enfants',{id:formData.id, data:formData})
        notify('Enfant modifié',{type: 'success'})
        navigate(`/dossiers/${formData.dossier}/show`)
    }

    return <Edit sx={{display:'flex'}}>
        <SimpleForm onSubmit={handleSubmit}>
            <BooleanInput source="enfant_place"/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.enfant_place &&
                    <>
                        <BooleanInput label="Informé consultation" source="informe_consultation_place"/>
                        <SelectInput label="Type de placement" source="type_placement" choices={[
                            { id: 'famille accueil', name: 'Famille d\'accueil' },
                            { id: 'placement domicile', name: 'Placement domicile' },
                            { id: 'foyer', name: 'Foyer' },
                        ]}/>
                    </>
                ||
                    <>
                        <SelectInput label="Informé consultation" source="informe_consultation" choices={[
                            { id: 'pere', name: 'Père' },
                            { id: 'mere', name: 'Mère' },
                            { id: 'pere_mere', name: 'Père et Mère' },
                        ]}/>
                        <SelectInput label="Favorable accompagnement" source="favorable_accompagnement" choices={[
                            { id: 'pere', name: 'Père' },
                            { id: 'mere', name: 'Mère' },
                            { id: 'pere_mere', name: 'Père et Mère' },
                        ]}/>
                    </>
                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
}