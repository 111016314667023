import {
    BooleanInput,
    Create,
    DateInput, ReferenceInput,
    SelectInput,
    SimpleForm,
    useDataProvider,
    useNotify, required
} from "react-admin";
import {useNavigate, useSearchParams} from "react-router-dom";
import React from "react";
import {RichTextInput} from "ra-input-rich-text"

export const RdvCreate = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        formData.dossier = searchParams.get('dossier_id')
        await dataProvider.create('rdvs',{data:formData})
        notify('Rendez-vous crée',{type: 'success'})
        navigate(`/dossiers/${formData.dossier}/show`)
    }

    return <Create sx={{display:'flex'}}>
        <SimpleForm onSubmit={handleSubmit}>
            <DateInput source="date_rdv" validate={required()}/>
            <SelectInput source="type" validate={required()} choices={[
                { id: 'gynecologue', name: 'Gynécologue'},
                { id: 'psychologue', name: 'Psychologue' },
                { id: 'infirmiere', name: 'Infirmier'},
                { id: 'medecin', name: 'Médecin'},
                { id: 'juriste', name: 'Juriste'},
                { id: 'avocat', name: 'Avocat'},
                { id: 'accueil', name: 'Accueil'},
                { id: 'animatrice', name: 'Animateur'},
                { id: 'psycho_corporel', name: 'Psycho corporel'},
                { id: 'info-contact', name: 'Info contact'},
            ]}/>
            <ReferenceInput source="praticien" validate={required()} reference="users" filter={{blocked:false, confirmed:true}}/>
            {/*<ReferenceInput source="professionnel" validate={required()} reference="users" filter={{"utilisateurs_roles":{nom:"pro"}}}/>*/}
            <BooleanInput source="est_honore"/>
            <RichTextInput source="notes"/>
        </SimpleForm>
    </Create>
}
