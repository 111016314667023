import {
    BooleanInput,
    Edit,
    DateTimeInput, ReferenceInput,
    SelectInput,
    SimpleForm,
    useDataProvider,
    useNotify,
    AutocompleteInput, required
} from "react-admin";
import {useNavigate, useSearchParams} from "react-router-dom";
import React from "react";
import {RichTextInput} from "ra-input-rich-text"

export const RdvEdit = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (formData) => {
        await dataProvider.update('rdvs',{id:formData.id, data:formData})
        notify('Rendez-vous modifié',{type: 'success'})
        navigate(`/dossiers/${formData.dossier}/show`)
    }

    return <Edit sx={{display:'flex'}}>
        <SimpleForm onSubmit={handleSubmit}>
            <DateTimeInput validate={required()} source="date_rdv"/>
            <SelectInput validate={required()} source="type" choices={[
                { id: 'gynecologue', name: 'Gynécologue'},
                { id: 'psychologue', name: 'Psychologue' },
                { id: 'infirmiere', name: 'Infirmier'},
                { id: 'medecin', name: 'Médecin'},
                { id: 'juriste', name: 'Juriste'},
                { id: 'avocat', name: 'Avocat'},
                { id: 'accueil', name: 'Accueil'},
                { id: 'animatrice', name: 'Animateur'},
                { id: 'psycho_corporel', name: 'Psycho corporel'},
                { id: 'info-contact', name: 'Info contact'},
            ]}/>
            <ReferenceInput validate={required()} source="praticien" reference="users" filter={{blocked:false, confirmed:true}}/>
            {/*<ReferenceInput source="praticien" reference="users" filter={{"utilisateurs_roles":{nom:"pro"}}}/>*/}
            <BooleanInput source="est_honore"/>
            <RichTextInput source="notes"/>
        </SimpleForm>
    </Edit>
}
