import {
    AutocompleteInput,
    BooleanInput,
    Edit, DateInput, FileField, FileInput,
    FormDataConsumer, NumberInput,
    ReferenceArrayInput,
    ReferenceInput, required,
    SelectInput,
    SimpleForm,
    TextInput, useDataProvider, useNotify, useGetRecordId, Toolbar, SaveButton, useRefresh
} from "react-admin";
import React, {useState, useEffect} from "react";
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {useNavigate, useSearchParams} from "react-router-dom";
import {BeneficiaireRecap} from '../../components/beneficiaireRecap.tsx'
import {IntervenantExterieurCreate} from "../intervenant-exterieur/intervenantExterieurCreate.tsx";

export const DossierEdit = () => {
    const dataProvider = useDataProvider();
    const [beneficiaire, setBeneficiaire] = useState()
    const notify = useNotify();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dossierId = useGetRecordId();
    const refresh = useRefresh()
    const [openDialog, setOpenDialog] = useState(false)
    const [intervenantType, setIntervenantType] = useState(null)

    useEffect(() => {
        if(dossierId){
            dataProvider.getOne('dossiers',{id:dossierId}).then(({data}) => {
                handleChangeBeneficiaire(data.beneficiaire)
            })
        }
        const beneficiaire = searchParams.get('beneficiaireId')
        if (beneficiaire){
            handleChangeBeneficiaire(beneficiaire)
        }
    },[])
    const handleSubmit = async (formData) => {
        const {data} = await dataProvider.update('dossiers',{id: dossierId, data: formData})
        notify('Dossier modifié',{type: 'success'})
        navigate(`/dossiers/${data.id}/show`)
    }

    const handleChangeBeneficiaire = (id) => {
        dataProvider.getOne('beneficiaires',{id:id})
            .then(({data: beneficiaire}) => {
                dataProvider.getMany("dossiers",{ids: beneficiaire.dossiers}).then(({data: dossiers}) => {
                    setBeneficiaire({...beneficiaire, dossiers: dossiers})
                })
            })
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const updateFormData = async () => {
        setOpenDialog(false)
        refresh()
    }

    const generateIntervenantModal = (orientationtType) => {
        setIntervenantType(orientationtType)
        setOpenDialog(true)
    }

    const DossierEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );

    return <Edit >
        <SimpleForm shouldUnregister onSubmit={handleSubmit} toolbar={<DossierEditToolbar/>}>
            {!beneficiaire && <>
                <Typography variant="h6">Bénéficiaire existant ?</Typography>
                <ReferenceInput reference="beneficiaires" source="beneficiaire" validate={required()}>
                    <AutocompleteInput fullWidth onChange={handleChangeBeneficiaire}/>
                </ReferenceInput>
                <Typography variant="h6" sx={{mb:2}}>Ou créer un nouveau bénéficiaire</Typography>
                <Button variant="contained" onClick={() => navigate(`/beneficiaires/create?redirectTo=dossiers`)}>Créer un nouveau bénéficiaire</Button>

            </> || <BeneficiaireRecap beneficiaire={beneficiaire} setBeneficiaire={setBeneficiaire}/>}
            {beneficiaire && <>
                <Accordion defaultExpanded={true} sx={{width: '100%', marginBottom: 3}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">Informations générales</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BooleanInput source="est_urgence" />
                        <SelectInput label="Statut du dossier" source="statut" validate={required()} choices={[
                            { id: 'en_cours', name: 'En cours' },
                            { id: 'en_sommeil', name: 'En sommeil' },
                            { id: 'cloture', name: 'Clotûré' },
                        ]}/>
                        <br/>
                        <DateInput source="date_arrive" validate={required()} sx={{mr:2}}/>
                        <ReferenceInput reference="antennes" validate={required()} source="antenne"/>
                        <ReferenceInput reference="users" source="referent" filter={{blocked:false, confirmed:true}}/>
                        <ReferenceArrayInput reference="users" source="professionnels" filter={{blocked:false, confirmed:true}}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false} sx={{width: '100%', marginBottom: 3}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">Situation familiale</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BooleanInput label="Séparation en cours ?" source="est_separation" />
                        {beneficiaire?.type === "adulte" &&
                            <>
                                <SelectInput sx={{pr: 3}} source="separation_status" validate={required()} choices={[
                                    { id: 'celibataire', name: 'Célibataire' },
                                    { id: 'concubinage', name: 'Concubinage' },
                                    { id: 'marie_france', name: 'Marié en France'},
                                    { id: 'marie_etranger', name: 'Marié à l\'étranger'},
                                    { id: 'divorce', name:'Divorcé'},
                                    { id: 'separe', name:'Séparé'}
                                ]}/>
                                <br/>
                                <TextInput source="nb_enfants" validate={required()}/>
                                <FormDataConsumer>
                                    {({formData, ...rest}) => formData.nb_enfants > 0 &&
                                        <>
                                            <TextInput sx={{ml:3}} source="nb_enfants_a_charges" validate={required()}/>
                                            <TextInput sx={{ml:3}} source="type_garde" validate={required()}/>
                                        </>
                                    }
                                </FormDataConsumer>
                                <br/>
                                <BooleanInput source="est_enceinte" />
                                <FormDataConsumer>
                                    {({formData, ...rest}) => formData.est_enceinte > 0 &&
                                        <DateInput  source="date_accouchement" validate={required()}/>
                                    }
                                </FormDataConsumer>
                            </> || beneficiaire?.type === "enfant" && <>
                                <SelectInput source="situation_parentale" choices={[
                                    { id: 'concubinage', name: 'Concubinage' },
                                    { id: 'marie', name: 'Marié' },
                                    { id: 'separe', name: 'Séparé'},
                                    { id: 'pacse', name: 'Pacsé'},
                                    { id: 'divorce', name:'Divorcé'},
                                    { id: 'famille_monoparentale ', name:'Famille monoparentale'},
                                    { id: 'autre', name:'Autre'}
                                ]}/>
                                <br/>
                                <SelectInput source="vit_avec" choices={[
                                    { id: 'pere', name: 'Père' },
                                    { id: 'mere', name: 'Mère' },
                                    { id: 'enfant_place', name: 'Enfant placé'},
                                ]}/>
                                <br/>
                                <NumberInput source="nb_freres_soeurs" validate={required()}/>
                                <FormDataConsumer>
                                    {({formData, ...rest}) => formData.nb_freres_soeurs > 0 &&
                                        <>
                                            <NumberInput sx={{ml:3}} source="nb_freres" validate={required()}/>
                                            <NumberInput sx={{ml:3}} source="nb_soeurs" validate={required()}/>
                                        </>
                                    }
                                </FormDataConsumer>
                            </>
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false} sx={{width: '100%', marginBottom: 3}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">Orientation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SelectInput source="orientation" choices={[
                            { id: 'professionnel_juridique', name: 'Professionnel juridique' },
                            { id: 'travailleur_social', name: 'Travailleur social' },
                            { id: 'professionnel_medical_et_paramedical', name: 'Professionnel médical et para-médical'},
                            { id: 'fiche de liaison', name: 'Fiche de liaison'},
                            { id: 'association', name: 'Association'},
                            { id: 'aide_sociale_enfance', name: 'Aide à l\'enfance'},
                            { id: 'universite', name: 'Université'},
                            { id: 'commissariat', name: 'Commissariat'},
                            { id: 'hopital', name: 'Hôpital'},
                            { id: 'media', name: 'Média'},
                            { id: 'bouche_a_oreille', name: 'Bouche à oreille'},
                            { id: 'professionnel_de_ws', name: 'Professionnel de WS'},
                            { id: 'collectif', name: 'Collectif'},
                            { id: 'etablissement_scolaire', name: 'Etablissement scolaire'},
                            { id: 'entreprise', name: 'Entreprise'},
                            { id: 'autre', name: 'Autre'},
                        ]}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.orientation && <>
                                <ReferenceArrayInput source="intervenants" reference="intervenant-exterieurs" filter={{type:formData.orientation}}/>
                                <Button variant="contained" onClick={() => generateIntervenantModal(formData.orientation)}>Créer un intervenant</Button>
                                {openDialog && <Dialog
                                    onClose={handleCloseDialog}
                                    open={openDialog}
                                    keepMounted
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>Créer un intervenant</DialogTitle>
                                    <DialogContent>
                                        <IntervenantExterieurCreate redirect={false} type={formData.orientation} onValidate={updateFormData}/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDialog}>Fermer</Button>
                                    </DialogActions>
                                </Dialog>}
                            </>}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.orientation == "autre" &&
                                <TextInput sx={{ml:3}} source="orientation_autre" />
                            }
                        </FormDataConsumer>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false} sx={{width: '100%', marginBottom: 3}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">Parcours migratoire</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BooleanInput label="Non / Oui" source="est_parcours_migratoire" />
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.est_parcours_migratoire &&
                                <SelectInput source="statut_migratoire" validate={required()} choices={[
                                    { id: 'demandeur_asile', name: 'Demandeur d\'asile'},
                                    { id: 'en_cours_regularisation', name: 'En cours de régularisation' },
                                    { id: 'sans_papier_aucune_demarche', name: 'Sans papier / aucune démarche'},
                                    { id: 'situation_reguliere', name: 'Situation régulière'},
                                ]}/>
                            }
                        </FormDataConsumer>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false} sx={{width: '100%', marginBottom: 3}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">Médical</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BooleanInput label="Traitement en cours ?" source="est_traitement" />
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.est_traitement &&
                                <>
                                    <TextInput source="nom_du_traitement" validate={required()}/>
                                    <br/>
                                </>
                            }
                        </FormDataConsumer>
                        <ReferenceArrayInput reference="addictions" source="addictions"/>
                        <ReferenceArrayInput reference="etat-psychiques" source="etat_psychiques"/>
                        <TextInput source="problemes_medicaux" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false} sx={{width: '100%', marginBottom: 3}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h4">Documents liés</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FileInput source="medias">
                            <FileField source="src" title="title" />
                        </FileInput>
                    </AccordionDetails>
                </Accordion>
            </>}
        </SimpleForm>
    </Edit>
}
