// in src/MyMenu.js
import { Menu } from "react-admin";
import {Folder, People, Person4, Apartment} from "@mui/icons-material";

export const CustomMenu = (props: any) => (
    <Menu {...props}>
        {/*<Menu.Item to="/beneficiaires" primaryText="Beneficiaires" leftIcon={<Person4 />} />*/}
        <Menu.Item to="/dossiers" primaryText="Dossiers" leftIcon={<Folder />} />
    </Menu>
);
