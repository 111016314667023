import {
    Grid,
    Typography,
    Paper,
    Chip,
    Box,
    Button,
    Divider,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
} from "@mui/material";
import {
    TextField,FileField, Show, SimpleShowLayout, DateField, BooleanField, useDataProvider, useNotify, ReferenceArrayField, SingleFieldList, ChipField, ReferenceField
} from 'react-admin';
import {useNavigate, useParams} from "react-router-dom"
import {CalendarMonth, Person, Notifications, Home} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {BeneficiaireRecap} from "../../components/beneficiaireRecap.tsx";
import {RdvAccordion} from "../../components/rdv-accordion.tsx";
export const DossierShow = () => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const { id } = useParams()

    const [dossier, setDossier] = useState();
    const [rdvs, setRdvs] = useState()
    const [intervenants, setIntervenants] = useState()
    const [evenements, setEvenements] = useState()
    const [enfants, setEnfants] = useState()
    const [currentUser, setCurrentUser] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [beneficiaire, setBeneficiaire] = useState(null)

    useEffect(() => {
        dataProvider.getOne("dossiers",{id}).then(({data}) => setDossier(data))
        setCurrentUser(JSON.parse(localStorage.user))
    },[id])

    useEffect(() => {
        if(dossier){
            dataProvider.getOne("beneficiaires",{id:dossier.beneficiaire}).then(({data}) => {
                setBeneficiaire(data)
            })
        }
        const fetchDossierDetails = async () => {
            const { data: rdvs } = await dataProvider.getMany("rdvs", { ids: dossier.rdvs })
            if (rdvs) {
                rdvs.sort((a, b) => Date.parse(b.date_rdv) - Date.parse(a.date_rdv))

                setRdvs(await Promise.all(rdvs.map(async (rdv) => {
                    if(typeof rdv.praticien === "object"){
                        return {...rdv, praticien: null}
                    }

                    const {data: professionnel} = await dataProvider.getOne("users",{id:rdv.praticien});
                    return {...rdv, praticien:professionnel}
                })))
            }
            dataProvider.getMany("intervenant-exterieurs",{ids: dossier.intervenants}).then(({data}) => setIntervenants(data))
            const {data: events} = await dataProvider.getMany("evenements",{ids: dossier.evenements})
            if(events){
                setEvenements(await Promise.all(events.map(async (event) => {
                    const {data:auteursResponse} = await dataProvider.getMany("auteurs",{ids: event.auteurs})
                    let {data:demarchesResponse} = await dataProvider.getMany("demarches",{ids: event.demarches})
                    let plaintesArray = []
                    if(demarchesResponse?.length > 0){
                        plaintesArray = await Promise.all(demarchesResponse.map(async (demarche) => {
                            if(demarche.plaintes.length > 0){
                                const {data: plaintesResponse} = await dataProvider.getMany("plaintes",{ids: demarche.plaintes})
                                return plaintesResponse
                            }
                            return []
                        }))
                        demarchesResponse = demarchesResponse.map((demarcheResponse, index) => {
                            return {... demarcheResponse, plaintes: plaintesArray[index]}
                        })
                    }

                    return {...event, auteurs : auteursResponse, demarches: demarchesResponse}
                })))
            }
            const {data: enfantsReponse} = await dataProvider.getMany("enfants",{ids: dossier.enfants})
            if(enfantsReponse){
                setEnfants(await Promise.all(enfantsReponse.map(async (enfant) => {
                    const {data:maisonResponse} = await dataProvider.getMany("maisons",{ids: enfant.maisons})
                    return {...enfant, maisons : maisonResponse}
                })))
            }
        }
        if(dossier){
            fetchDossierDetails()
        }
    },[dossier])

    if(!dossier){
        return null
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    return <Grid container sx={{px:5, py:5}} spacing={3}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box sx={{p:2, border:'3px solid', borderColor:'primary.main'}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                        <Typography variant="h4">Dossier : {dossier.numero_dossier}</Typography>
                        <Button variant="contained" onClick={() => navigate(`/dossiers/${dossier.id}/edit`)}>Modifier</Button>
                    </Box>
                    {beneficiaire && <Box sx={{mt:2}}>
                        <BeneficiaireRecap viewType="show" beneficiaire={beneficiaire} setBeneficiaire={setBeneficiaire}/>
                        <Show actions={false} title={`Dossier ${dossier.numero_dossier}`}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <SimpleShowLayout divider={<Divider flexItem />}>
                                        <Typography variant="h6" sx={{textDecoration:'underline'}}>Informations</Typography>
                                        <ReferenceField source="referent" reference="users"/>
                                        <ReferenceField source="antenne" reference="antennes"/>
                                        <ReferenceArrayField source="professionnels" reference="users">
                                            <SingleFieldList>
                                                <ChipField source="nom" />
                                            </SingleFieldList>
                                        </ReferenceArrayField>
                                        <BooleanField source="est_urgence"/>
                                        <TextField source="numero_dossier"/>
                                        <DateField source="date_arrive"/>
                                        {beneficiaire.type === "enfant" && <TextField source="ecole"/>}
                                        {beneficiaire.type === "enfant" && <TextField source="classe"/>}
                                        <TextField source="situation_parentale" />
                                        {beneficiaire.type === "enfant" && <TextField source="vit_avec" />}
                                        {beneficiaire.type === "adulte" && <BooleanField source="est_enceinte"/>}
                                        {beneficiaire.type === "adulte" && dossier.est_enceinte && <DateField source="date_accouchement"/>}
                                        {beneficiaire.type === "enfant" && <TextField source="nb_freres_soeurs"/>}
                                        {beneficiaire.type === "enfant" && <TextField source="nb_freres"/>}
                                        {beneficiaire.type === "enfant" && <TextField source="nb_soeurs"/>}
                                        {beneficiaire.type === "adulte" && <TextField source="nb_enfants"/>}
                                        {beneficiaire.type === "adulte" && <TextField source="nb_enfants_a_charges"/>}
                                        {beneficiaire.type === "adulte" && <TextField source="type_garde"/>}
                                    </SimpleShowLayout>
                                </Grid>
                                <Grid item xs={4}>
                                    <SimpleShowLayout divider={<Divider flexItem />}>
                                        <Typography variant="h6" sx={{textDecoration:'underline'}}>Migrations</Typography>
                                        <BooleanField source="est_parcours_migratoire"/>
                                        {dossier.est_parcours_migratoire && <TextField source="status_migratoire"/>}
                                        <Typography variant="h6" sx={{textDecoration:'underline'}}>Orientation</Typography>
                                        <TextField source="orientation" />
                                        {dossier.orientation === "autre" && <TextField source="orientation_autre" />}
                                    </SimpleShowLayout>
                                </Grid>
                                <Grid item xs={4}>
                                    <SimpleShowLayout divider={<Divider flexItem />}>
                                        <Typography variant="h6" sx={{textDecoration:'underline'}}>Traitement</Typography>
                                        <BooleanField source="est_traitement"/>
                                        {dossier.est_traitement && <TextField source="nom_du_traitement"/>}
                                        <TextField source="problemes_medicaux"/>
                                        <ReferenceArrayField source="addictions" reference="addictions">
                                            <SingleFieldList>
                                                <ChipField source="nom" />
                                            </SingleFieldList>
                                        </ReferenceArrayField>
                                        <ReferenceArrayField source="etat_psychiques" reference="etat-psychiques">
                                            <SingleFieldList>
                                                <ChipField source="nom" />
                                            </SingleFieldList>
                                        </ReferenceArrayField>
                                    </SimpleShowLayout>
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleShowLayout divider={<Divider flexItem />}>
                                        <Typography variant="h6" sx={{textDecoration:'underline'}}>Médias</Typography>
                                        <FileField source="medias" title="name" src="url" target="_blank"/>
                                    </SimpleShowLayout>
                                </Grid>
                            </Grid>
                        </Show>
                    </Box>}
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={3} sx={{mt:1}}>
            <Grid item xs={12}>
                <Box sx={{p:2, border:'3px solid', borderColor:'primary.main'}}>
                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h4">Evénements ({evenements?.length})</Typography>
                        <Button variant="contained" onClick={() => navigate(`/evenements/create?dossier_id=${dossier.id}`)}>Ajouter</Button>
                    </Box>
                    <Grid container spacing={2} sx={{mt:2}}>
                        {evenements?.map((evenement) => <Grid key={evenement.id} item xs={6}>
                                <Paper sx={{p:2}}>
                                    <Box sx={{display:'flex'}}>
                                        <Notifications sx={{mr:1}}/>
                                        <Typography>{(new Date(evenement.date_debut)).toLocaleDateString()} - {(new Date(evenement.date_fin)).toLocaleDateString()} ({evenement.id})</Typography>
                                    </Box>
                                    <Typography sx={{mt:1}}>Fréquence : {evenement.frequence}</Typography>
                                    <Typography>Lieux : {evenement.lieux}</Typography>
                                    <Typography>Témoins : {evenement.temoins}</Typography>
                                    {evenement.est_arme && <Typography>Arme : {evenement.arme_type}</Typography>}
                                    {evenement.auteurs?.length > 0 &&
                                        <Box>
                                            <Divider sx={{my:2}}/>
                                            <Typography sx={{textDecoration:'underline'}}>Auteurs ({evenement.auteurs?.length}) :</Typography>
                                            {evenement.auteurs.map((auteur) => <Paper key={auteur.id} sx={{p:1,mt:1}}>
                                                    <Typography>Nom : {auteur.nom_lien_victime}</Typography>
                                                    <Typography>Date naissance : {(new Date(auteur.date_naissance)).toLocaleDateString()}</Typography>
                                                    <Typography>Profession : {auteur.profession}</Typography>
                                                    <Button variant="outlined" onClick={() => navigate(`/auteurs/${auteur.id}/edit?dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier l'auteur</Button>
                                                </Paper>
                                            )}
                                        </Box>
                                    }
                                    {evenement.demarches?.length > 0 &&
                                        <Box sx={{mt:2}}>
                                            <Divider sx={{my:2}}/>
                                            <Typography sx={{textDecoration:'underline'}}>Démarches ({evenement.auteurs?.length}) :</Typography>
                                            {evenement.demarches.map((demarche, index) => <Paper key={demarche.id} sx={{p:1,mt:1}}>
                                                <Typography>Démarche n°{index + 1}</Typography>
                                                {demarche.statut === "en_cours" && <Chip label="En cours" color="success" sx={{mt:1}}/> ||
                                                demarche.statut === "passees" && <Chip label="Passée" color="error" sx={{mt:1}}/> ||
                                                demarche.statut === "aucune" && <Chip label="Aucune" color="default" sx={{mt:1}}/>}
                                                <Box>
                                                    {demarche.plaintes.map((plainte) => <Paper sx={{p:1,mt:2}} key={plainte.id}>
                                                        <Typography sx={{textDecoration: 'underline'}}>{(new Date(plainte.date)).toLocaleDateString()} - {plainte.main_courante && "Main courante" || "Plainte"}</Typography>
                                                        <Typography>{plainte.lieu}</Typography>
                                                        {plainte.main_courante && <Typography>Nombre : {plainte.main_courante_nb}</Typography> ||
                                                            <>
                                                                <Typography>{plainte.plainte_retire && "Plainte retirée" || "Plainte non retirée"}</Typography>
                                                                {plainte.plainte_retire && <Typography>Raison : {plainte.raison}</Typography>}
                                                                <Typography>Expertise médicolégale : {plainte.expertise_medicolegale && "Oui" || "Non"}</Typography>
                                                                <Typography><u>Commentaire :</u> <br/>{plainte.commentaire}</Typography>
                                                            </>
                                                        }
                                                        <Button variant="outlined" onClick={() => navigate(`/plaintes/${plainte.id}/edit?demarche_id=${demarche.id}&dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier la plainte</Button>
                                                    </Paper>)}
                                                </Box>
                                                <Button sx={{mt:2}} fullWidth variant="contained" onClick={() => navigate(`/plaintes/create?demarche_id=${demarche.id}&dossier_id=${dossier.id}`)}>Ajouter une plainte</Button>
                                                <Button variant="outlined" onClick={() => navigate(`/demarches/${demarche.id}/edit?evenement_id=${evenement.id}&dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier la démarche</Button>
                                            </Paper>)}
                                        </Box>
                                    }
                                    <Grid container spacing={2} sx={{mt:1}}>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" onClick={() => navigate(`/auteurs/create?evenement_id=${evenement.id}&dossier_id=${dossier.id}`)}>Ajouter un auteur</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" onClick={() => navigate(`/demarches/create?evenement_id=${evenement.id}&dossier_id=${dossier.id}`)}>Ajouter une démarche</Button>
                                        </Grid>
                                    </Grid>
                                    <Button variant="outlined" onClick={() => navigate(`/evenements/${evenement.id}/edit?dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier l'événement</Button>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{p:2, border:'3px solid', borderColor:'primary.main'}}>
                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h4">Enfants ({enfants?.length})</Typography>
                        <Button variant="contained" onClick={() => navigate(`/enfants/create?dossier_id=${dossier.id}`)}>Ajouter</Button>
                    </Box>
                    <Grid container spacing={2} sx={{mt:2}}>
                        {enfants?.map((enfant) => <Grid key={enfant.id} item xs={6}>
                                <Paper sx={{p:2}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box sx={{display:'flex',flexDirection: 'column'}}>
                                                <Person/>
                                                <Typography>{enfant.enfant_place ? "Enfant placé" : "Enfant non placé"}</Typography>
                                                {!enfant.enfant_place && <>
                                                    <Typography>Informé consultation : {enfant.informe_consultation}</Typography>
                                                    <Typography>Favorable accompagnement : {enfant.favorable_accompagnement}</Typography>
                                                </> || <Typography>Type de placement : {enfant.type_placement}</Typography>}
                                            </Box>
                                        </Grid>
                                        {enfant.enfant_place && <Grid item xs={12} sx={{mt:2}}>
                                            {enfant.maisons?.map((maison, index) => <Paper key={maison.id} sx={{p:1}}>
                                                <Box sx={{display:'flex'}}>
                                                    <Home sx={{mr:1}}/>
                                                    <Typography sx={{mb:1}}>{maison.nom}</Typography>
                                                </Box>
                                                <Typography><u>Type: </u>{maison.maison_type.charAt(0).toUpperCase() + maison.maison_type.slice(1)}</Typography>
                                                {maison.maison_type === "foyer" && <>
                                                    <Typography><u>Référent:</u> {maison.referent}</Typography>
                                                    <Typography><u>Tél:</u> {maison.referent_telephone}</Typography>
                                                </> || maison.maison_type === "famille accueil" && <>
                                                    <Typography><u>Tél:</u> {maison.telephone_maison}</Typography>
                                                </>}
                                                <Typography><u>Email:</u> {maison.email}</Typography>
                                                <Button variant="outlined" onClick={() => navigate(`/maisons/${maison.id}/edit?dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier la maison</Button>

                                            </Paper>)}
                                            <Button sx={{mt:2}} fullWidth onClick={() => navigate(`/maisons/create?enfant_id=${enfant.id}&dossier_id=${dossier.id}`)} variant="contained">Ajouter un logement</Button>
                                        </Grid>}
                                        <Button variant="outlined" onClick={() => navigate(`/enfants/${enfant.id}/edit?dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier l'enfant</Button>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{p:2, border:'3px solid', borderColor:'primary.main'}}>
                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h4">Intervenants extérieurs ({intervenants?.length})</Typography>
                        <Button variant="contained" onClick={() => navigate(`/intervenant-exterieurs/create?dossier_id=${dossier.id}`)}>Ajouter</Button>
                    </Box>
                    <Grid container spacing={2} sx={{mt:2}}>
                        {intervenants?.map((intervenant) => <Grid key={intervenant.id} item xs={4}>
                                <Paper sx={{p:2}}>
                                    <Box sx={{display:'flex'}}>
                                        <Person sx={{mr:1}}/>
                                        <Typography>{intervenant.nom}</Typography>
                                    </Box>
                                    <Typography sx={{mt:1}}>Coordonnées : {intervenant.coordonnees}</Typography>
                                    <Button variant="outlined" onClick={() => navigate(`/intervenant-exterieurs/${intervenant.id}/edit?dossier_id=${dossier.id}`)} sx={{mt:1}} fullWidth>Modifier</Button>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{p:2, border:'3px solid', borderColor:'primary.main'}}>
                    <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h4">Rendez-vous ({rdvs?.length})</Typography>
                        <Button variant="contained" onClick={() => navigate(`/rdvs/create?dossier_id=${dossier.id}`)}>Ajouter</Button>
                    </Box>
                    <Grid container spacing={2} sx={{mt:2}}>
                        {rdvs?.map((rdv) => <RdvAccordion rdv={rdv} dossier={dossier} currentUser={currentUser}/>)}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    </Grid>
}
