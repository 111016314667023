import {fetchUtils, Admin, Resource, CustomRoutes} from "react-admin";
import { Route} from 'react-router-dom';
import raStrapiRest from "./ra-strapi-rest";
import { AppLayout } from "./Layout";
import {BeneficiaireCreate} from "./pages/beneficiaire/beneficiaireCreate.tsx";
import {BeneficiaireList} from "./pages/beneficiaire/beneficiaireList.tsx";
import {BeneficiaireEdit} from "./pages/beneficiaire/beneficiaireEdit.tsx";
import {BeneficiaireShow} from "./pages/beneficiaire/beneficiaireShow.tsx";
import {DossierCreate} from "./pages/dossier/dossierCreate.tsx";
import {DossierList} from "./pages/dossier/dossierList.tsx";
import {DossierShow} from "./pages/dossier/dossierShow.tsx";
import {DossierEdit} from "./pages/dossier/dossierEdit.tsx";
import {IntervenantExterieurCreate} from "./pages/intervenant-exterieur/intervenantExterieurCreate.tsx";
import {IntervenantExterieurEdit} from "./pages/intervenant-exterieur/intervenantExterieurEdit.tsx";
import {EvenementCreate} from "./pages/evenement/evenementCreate.tsx";
import {EvenementEdit} from "./pages/evenement/evenementEdit.tsx";
import {RdvCreate} from "./pages/rdv/rdvCreate.tsx";
import {RdvEdit} from "./pages/rdv/rdvEdit.tsx";
import {AuthProvider} from "./authProvider.tsx"
import {AuteurCreate} from "./pages/auteur/auteurCreate.tsx"
import {AuteurEdit} from "./pages/auteur/auteurEdit.tsx"
import {EnfantCreate} from "./pages/enfant/enfantCreate.tsx"
import {EnfantEdit} from "./pages/enfant/enfantEdit.tsx"
import {MaisonCreate} from "./pages/maison/maisonCreate.tsx"
import {MaisonEdit} from "./pages/maison/maisonEdit.tsx"
import {DemarcheCreate} from "./pages/demarche/demarcheCreate.tsx"
import {DemarcheEdit} from "./pages/demarche/demarcheEdit.tsx"
import {PlainteCreate} from "./pages/plainte/plainteCreate.tsx"
import {PlainteEdit} from "./pages/plainte/plainteEdit.tsx"
import { i18nProvider } from './i18nProvider.tsx';
import { ResetPassword } from "./pages/login/reset-password.tsx";
import {LoginPage} from "./pages/login/login-page.tsx";

const strapiApiUrl = import.meta.env.VITE_STRAPI_API_URL;

const httpClient = (url: string, options: any = {}) => {
    options.headers = options.headers || new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`);
    return fetchUtils.fetchJson(url, options);
};

export const dataProvider = raStrapiRest(strapiApiUrl, httpClient);

const myTheme = {
    palette: {
        primary:{
            main: '#C24A58'
        },
        background: {
            default: '#fafafb',
        },
        secondary: {
            light: '#6ec6ff',
            main: '#2196f3',
            dark: '#0069c0',
            contrastText: '#fff',
        },
    },
    typography: {
        h6: {
            fontWeight: 400,
        },
    },
    sidebar: {
        width: 240,
        closedWidth: 50,
    },
    components: {
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    '&$disabled': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled' as const,
                margin: 'dense' as const,
                size: 'small' as const,
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'filled' as const,
                margin: 'dense' as const,
                size: 'small' as const,
            },
        },
    },
};

const App = () => (
    <Admin
        layout={AppLayout}
        dataProvider={dataProvider}
        theme={myTheme}
        authProvider={AuthProvider}
        i18nProvider={i18nProvider}
        loginPage={<LoginPage/>}
    >
        <CustomRoutes noLayout>
            <Route path="/forget-password" element={<ResetPassword/>} />
        </CustomRoutes>
        <Resource name="dossiers" list={DossierList} create={DossierCreate} edit={DossierEdit} show={DossierShow}/>
        <Resource name="beneficiaires" list={BeneficiaireList} edit={BeneficiaireEdit} create={BeneficiaireCreate} show={BeneficiaireShow} recordRepresentation={(record) => `${record.nom} ${record.prenom}`}/>
        <Resource name="antennes" recordRepresentation="nom"/>
        <Resource name="users" recordRepresentation={(record) => `${record.prenom} ${record.nom}`}/>
        <Resource name="addictions" recordRepresentation={(record) => `${record.nom}`}/>
        <Resource name="etat-psychiques" recordRepresentation={(record) => `${record.nom}`}/>
        <Resource name="intervenant-exterieurs" create={IntervenantExterieurCreate} edit={IntervenantExterieurEdit} recordRepresentation="nom"/>
        <Resource name="evenements" create={EvenementCreate} edit={EvenementEdit} recordRepresentation="nom"/>
        <Resource name="evenement-types" recordRepresentation="nom"/>
        <Resource name="evenement-type-specifiques" recordRepresentation="nom"/>
        <Resource name="utilisateurs-roles" recordRepresentation="nom"/>
        <Resource name="rdvs" create={RdvCreate} edit={RdvEdit} recordRepresentation={(record) => record.date_rdv}/>
        <Resource name="auteurs" create={AuteurCreate} edit={AuteurEdit}/>
        <Resource name="enfants" create={EnfantCreate} edit={EnfantEdit}/>
        <Resource name="maisons" create={MaisonCreate} edit={MaisonEdit}/>
        <Resource name="demarches" create={DemarcheCreate} edit={DemarcheEdit}/>
        <Resource name="plaintes" create={PlainteCreate} edit={PlainteEdit}/>
    </Admin>
);

export default App;
