import {Button, Grid} from "@mui/material";
import {
    BooleanInput,
    DateInput,
    Form,
    FormDataConsumer,
    SelectInput,
    SimpleForm,
    TextInput,
    Create,
    useDataProvider, useNotify, ReferenceArrayInput, useGetList
} from "react-admin";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from 'react-hook-form'


export const EvenementCreate = () => {
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const [searchParams] = useSearchParams();
    const [violenceType, setViolenceType] = useState([]);

    useEffect(() => {
        dataProvider.getList('evenement-types',{
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' },
            filter: {},
        }).then(({data}) => {
            setViolenceType(data)
        })
    },[])

    const handleSubmit = async (formData) => {
        formData.dossier = searchParams.get('dossier_id')
        await dataProvider.create('evenements',{data:formData})
        notify('Evenement créé',{type: 'success'})
        navigate(`/dossiers/${formData.dossier}/show`)
    }

    return <Create>
        <SimpleForm onSubmit={handleSubmit} sx={{display:'flex'}}>
        <Grid container columnSpacing={3}>
            <Grid item xs={12}>
                <ReferenceArrayInput source="type" reference="evenement-types"/>
            </Grid>
            <FormDataConsumer>
                {({formData, ...rest}) => <Grid item xs={12}>
                        {violenceType.length > 0 && formData.type?.map((item) => <ReferenceArrayInput key={item} source={(violenceType.filter((violence) => violence.id === item))[0].equivalent_field} reference="evenement-type-specifiques" filter={{evenement_type:item}}/>)}
                    </Grid>
                }
            </FormDataConsumer>
            <Grid item xs={12}>
                <SelectInput source="element_declencheur" choices={[
                    { id: 'vie_commune', name: 'Vie commune'},
                    { id: 'separation', name: 'Séparation' },
                    { id: 'grossesse', name: 'Grossesse'},
                    { id: 'evenement_isole', name: 'Evénement isolé'},
                    { id: 'autre', name: 'Autre'},
                ]}/>
            </Grid>
            <Grid item xs={12}>
                <SelectInput source="statut" choices={[
                    { id: 'en_cours', name: 'En cours'},
                    { id: 'passee', name: 'Passées' },
                ]}/>
            </Grid>
            <Grid item xs={12}>
                <DateInput source="date_debut"></DateInput>
                <DateInput sx={{ml:2}} source="date_fin"></DateInput>
            </Grid>
            <Grid item xs={12}>
                <TextInput name="frequence" source="frequence"></TextInput>
            </Grid>
            <Grid item xs={12}>
                <BooleanInput name="est_arme" source="est_arme"></BooleanInput>
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.est_arme &&
                        <Grid item xs={6}>
                            <TextInput name="arme_type" source="arme_type"></TextInput>
                        </Grid>
                    }
                </FormDataConsumer>
            </Grid>
            <Grid item xs={12}>
                <TextInput name="temoins" source="temoins"></TextInput>
            </Grid>
            <Grid item xs={12}>
                <TextInput name="lieux" source="lieux"></TextInput>
            </Grid>
        </Grid>
    </SimpleForm>
    </Create>
}